import React, { useContext, useEffect, useRef, useState } from "react";
import usePermission from "../../../hooks/usePermissions";
import "./house.css";
import {
  colors,
  formattedPrice,
  onlyNumbers,
  convertToTitleCase,
  removeUnderscore,
} from "../../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../Components/Common/Button";
import FormInput from "../../../Components/Common/FormInput";
import MultitextInput from "../../../Components/Common/MultitextInput";
import { toast } from "react-toastify";
import Icons from "../../../Components/Common/Icons";
import { useNav } from "../../../hooks/useNav";
import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { debounce } from "lodash";
import { fileExtension, getBase64Image } from "../../../utils/imageUtils";
import Spinner from "../../../Components/Spinner";
import CustomDropdown from "../../../Components/Common/Dropdown";
import useCity from "../../../hooks/useCity";
import useAllStates from "../../../hooks/useAllStates";
import Modal from "../../../Components/Common/Modal";
import "../House/createHouse.css";
import {
  houseTypes,
  houseStatusLists,
  categoryLists,
  landTitleDocs,
  ownershipDoc,
  imageTypes,
  segmentLists,
} from "../../../utils/mockData";
import { useForm } from "react-hook-form";
import useGetHouse from "./hooks/useGetHouse";
import useDeleteHouse from "./hooks/useDeleteHouse";
import useGenerateQRCode from "./hooks/useGenerateQRCode";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { HiDotsHorizontal } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import useUploadImages from "./hooks/useUploadImages";
import useDeleteImages from "./hooks/useDeleteImages";
import CheckInput from "../../../Components/Common/CheckInput";
import PlanTypeCard from "../../../Components/Common/PlanTypeCard";
import PlanTypeContext from "../../../Context/PlanTypeContext";

const House = () => {
  const { accessUpdateHouse, accessDeleteHouse } = usePermission();
  const { planType, interestType, setPlanType, setInterestType } =
    useContext(PlanTypeContext);

  const { putRequest } = useAxiosBase();
  const params = useParams();
  const houseId = params?.id;
  const justCreated = params?.justCreated;
  const { uploadingHouseImages } = useUploadImages(houseId);
  const { deleteHouseImages } = useDeleteImages(houseId);

  const navigate = useNavigate();

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  if (justCreated) {
    toast.success("Created Successfully!");
  }
  const { data: house, isLoading, isRefetching } = useGetHouse(houseId);
  const { data: generateQRCode } = useGenerateQRCode(houseId);
  const { deleteHouse } = useDeleteHouse(houseId);

  const refArray = React.useRef([]);

  const [showModal, setShowModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showPlanTypeModal, setShowPlanTypeModal] = useState(false);
  const [
    showSelectInterestOrCompoundType,
    setShowSelectInterestOrCompoundType,
  ] = useState(false);

  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageIDsToDelete, setImageIDsToDelete] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState(imageTypes[0]?.id);
  const [cityId, setCityId] = useState(house?.city?.id);
  const [stateId, setStateId] = useState(house?.city?.stateId);

  const [completionPercentage, setCompletionPercentage] = useState();
  const [completionDate, setCompletionDate] = useState();

  const [houseType, setHouseType] = useState();
  const [houseTypeName, setHouseTypeName] = useState();
  const [houseSegment, setHouseSegment] = useState("");
  const [houseSegmentName, setHouseSegmentName] = useState("");
  const [houseCategory, setHouseCategory] = useState("");
  const [houseCategoryName, setHouseCategoryName] = useState("");
  const [completionStatus, setCompletionStatus] = useState();
  const [completionStatusName, setCompletionStatusName] = useState("");
  const [ownershipDocument, setOwnershipDocument] = useState();
  const [ownershipDocumentName, setOwnershipDocumentName] = useState();
  const [landTitleDocument, setLandTitleDocument] = useState();
  const [landTitleDocumentName, setLandTitleDocumentName] = useState();

  const [features, setFeatures] = useState({});
  const [allGeneralFeatures, setAllGeneralFeatures] = useState(false);
  const [allKitchenFeatures, setAllKitchenFeatures] = useState(false);
  const [allLivingRoomFeatures, setAllLivingRoomFeatures] = useState(false);
  const [allOutdoorFeatures, setAllOutdoorFeatures] = useState(false);
  const [allHomeOfficeFeatures, setAllHomeOfficeFeatures] = useState(false);
  const [allSafetySecurityFeatures, setAllSafetySecurityFeatures] =
    useState(false);
  const [allParkingFeatures, setAllParkingFeatures] = useState(false);
  const [allutilitiesFeatures, setAllutilitiesFeatures] = useState(false);
  const [allCommunityFeatures, setAllCommunityFeatures] = useState(false);
  const [allPetFeatures, setAllPetFeatures] = useState(false);
  // const { imageToDisplay, imageObject, handleChange } = useSaveSingleImage({
  //   optionsId: "ESTATE_IMAGE",
  // });

  const saveImageName = debounce(() => {
    if (!editableImageName.trim()) {
      toast.error("Please input a valid property name.");
      return;
    }

    if (!editableImageType.trim()) {
      toast.error("Please select a valid image type.");
      return;
    }

    if (selectedImage) {
      // Map through the imagesToDisplay array to update the name and type of the selected image
      const updatedImages = imagesToDisplay.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );
      const updatedImageObject = imageObject.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );

      setImagesToDisplay(updatedImages);
      setImageObject(updatedImageObject);

      setShowImageModal(false);
      setSelectedImage(null); // Reset selected image
      setEditableImageName(""); // Reset editable name
      setEditableImageType(imageTypes[0]?.id); // Reset editable type
    }
  }, 300);

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (files.length !== 0 && files[0].length !== 0) {
      for (let file of files) {
        // Check if file is of allowed type
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only .png, .jpeg, and .jpg files are allowed.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        if (file.size > 2 * 1024 * 1024) {
          toast.error("Image size shouldn't be more than 2MB.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        // Check if an image with the same name already exists
        if (imagesToDisplay.some((img) => img.name === file.name)) {
          toast.error(`An image with the name ${file.name} already exists.`);

          // Reset file input value
          event.target.value = null;
          return;
        }

        const displayImg = URL.createObjectURL(file);
        setShowImageModal(true);
        setSelectedImage({ document: displayImg, name: file.name });
        setEditableImageName(file.name);
        setImagesToDisplay((prevImages) => [
          { document: displayImg, name: file.name },
          ...prevImages,
        ]);
        URL.revokeObjectURL(file);
        const base64 = await getBase64Image(file);
        const newImage = {
          base64: base64,
          name: file.name,
          optionId: "ESTATE_IMAGE",
          description: "",
          extension: fileExtension(file),
        };
        setImageObject((prevImages) => [newImage, ...prevImages]);
      }

      // Reset file input value
      event.target.value = null;
      return;
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  const hiddenFileInputRef = useRef(null);
  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    } else {
      console.error("The file input is not yet available.");
    }
  };

  const { data: states } = useAllStates({
    onSuccess: (data) => {
      const sortedStates = data?.sort((a, b) =>
        a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0
      );
      refetch();
    },
  });

  const { refetch, data: city, isLoading: cityLoading } = useCity({ stateId });

  useNav({
    hasBackButton: true,
    leftButtonDelete: true,
    leftBtnDelete: () => setShowModal(true),
  });

  const getSelectedFeatures = () => {
    const selectedFeatures = {
      id: house?.features?.[0]?.id,
    };

    Object.keys(features)?.forEach((group) => {
      features[group]?.forEach((feature) => {
        if (feature.checked) {
          selectedFeatures[feature.name] = true;
        }
      });
    });

    return selectedFeatures;
  };

  // HANDLE UPDATE HOUSE
  const updateHouse = useMutation({
    mutationFn: (data) =>
      putRequest({
        url: `developer/house/${houseId}/update`,
        data: data,
      }).then((res) => {
        if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
          toast.error(res?.response?.data?.value?.message);
          return;
        }
        if (res?.data?.value?.statusCode === 200) {
          navigate("/properties", { state: { refresh: true } });
          toast.success(res?.data?.value?.message);
          return;
        }
        return res.data;
      }),
  });

  const { register, handleSubmit, setValue, reset } = useForm();
  const onSubmit = (data) => {
    if (imagesToDisplay?.length <= 0) {
      toast.error("Please select an Image");
      return;
    }

    const selectedFeatures = getSelectedFeatures();

    data.ownerType = "DEVELOPER";
    data.type = houseType;
    data.feature = selectedFeatures;
    data.cityId = cityId;
    data.completionStatus = completionStatus;
    data.category = houseCategory;
    data.completionPercentage = completionPercentage;
    data.ownershipDocument = ownershipDocument;
    data.landTitleDocument = landTitleDocument;
    data.bedrooms =
      typeof data?.bedrooms === "string"
        ? parseFloat(data?.bedrooms.replace(/,/g, ""))
        : data?.bedrooms;
    data.bathrooms =
      typeof data?.bathrooms === "string"
        ? parseFloat(data?.bathrooms.replace(/,/g, ""))
        : data?.bathrooms;
    data.guestToilets =
      typeof data?.guestToilets === "string"
        ? parseFloat(data?.guestToilets.replace(/,/g, ""))
        : data?.guestToilets;
    data.squareMeters =
      typeof data?.squareMeters === "string"
        ? parseFloat(data?.squareMeters.replace(/,/g, ""))
        : data?.squareMeters;
    data.images = [];
    data.estateId = house?.estate?.id ?? null;

    if (imageIDsToDelete?.length > 0) {
      handleDeleteImagesFunction();
    }

    // console.log("data", data);
    updateHouse.mutate(data, {
      onSuccess: () => {
        handleImageUploadFunction();
      },
    });
  };

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      setInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;

    if (id) {
      setPlanType(id);
    }
  };

  const handleImageUploadFunction = () => {
    if (houseId) {
      imageObject.map((image) => {
        uploadingHouseImages.mutate(image);
      });
    }
  };

  const handleDeleteImagesFunction = () => {
    deleteHouseImages.mutate(imageIDsToDelete);
  };

  // HANDLE GENERATE QR CODE
  const downloadQRCode = () => {
    if (generateQRCode) {
      // Convert base64 to blob
      const byteCharacters = atob(generateQRCode);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });

      // Create anchor element
      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = "QRCode.png"; // Specify file name

      // Trigger download
      anchor.click();

      // Clean up
      URL.revokeObjectURL(anchor.href);
    } else {
      console.error("generateQRCode is not a valid base64 string");
    }
  };

  const handleCopyPropertyLink = async () => {
    const propertyLink = `${window.location.origin}/properties/house/${houseId}`;
    navigator.clipboard
      .writeText(propertyLink)
      .then(() => {
        toast.success("Property link copied to clipboard");
      })
      .catch((error) => {
        toast.error("Failed to copy property link to clipboard");
      });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  // Function to delete an image
  const handleRemoveImages = () => {
    if (imageToDelete) {
      setImagesToDisplay((prevImages) =>
        prevImages.filter((img) => img !== imageToDelete)
      );

      setImageObject((prevImages) =>
        prevImages.filter((img) => img.name !== imageToDelete.name)
      );

      setImageIDsToDelete((prevIds) => [...prevIds, imageToDelete.id]);
    }
    setShowDeleteModal(false);
  };

  const handleIndividualFeatureToggle = (group, featureName) => {
    const updatedFeatures = features[group].map((feature) =>
      feature.name === featureName
        ? { ...feature, checked: !feature.checked }
        : feature
    );

    setFeatures({
      ...features,
      [group]: updatedFeatures,
    });
  };

  const handleToggleSelectAllGroupedFeatures = (category, value) => {
    const newCheckedStatus = !value;

    const updatedFeatures = {
      ...features,
      [category]: features[category].map((feature) => ({
        ...feature,
        checked: newCheckedStatus,
      })),
    };

    setFeatures(updatedFeatures);
  };

  useEffect(() => {
    if (house) {
      const { features, ...houseWithoutFeatures } = house;
      reset(houseWithoutFeatures);

      setValue("price", formattedPrice(house.price));
      setImagesToDisplay(house?.images);

      if (house?.completionDate) {
        const datePart = house?.completionDate.split("T")[0];
        setValue("completionDate", datePart);
      }

      setFeatures({
        generalFeatures: [
          {
            name: "hasEnsuite",
            checked: house?.features?.[0]?.hasEnsuite,
            label: "Ensuite",
          },
          {
            name: "hasPoPCeiling",
            checked: house?.features?.[0]?.hasPoPCeiling,
            label: "POP Ceiling",
          },
          {
            name: "hasWalkInClosets",
            checked: house?.features?.[0]?.hasWalkInClosets,
            label: "Walk-In-Closets",
          },
          {
            name: "hasAirConditioning",
            checked: house?.features?.[0]?.hasAirConditioning,
            label: "Air Conditioning",
          },
          {
            name: "hasSpeedInternet",
            checked: house?.features?.[0]?.hasSpeedInternet,
            label: "High Speed Internet",
          },
          {
            name: "hasWineCeller",
            checked: house?.features?.[0]?.hasWineCeller,
            label: "Wine Celler",
          },
          {
            name: "hasFurnished",
            checked: house?.features?.[0]?.hasFurnished,
            label: "Furnished",
          },
          {
            name: "hasWifi",
            checked: house?.features?.[0]?.hasWifi,
            label: "Wi-Fi",
          },
          {
            name: "hasFibreOptics",
            checked: house?.features?.[0]?.hasFibreOptics,
            label: "Fibre Optics",
          },
          {
            name: "hasSatelliteTV",
            checked: house?.features?.[0]?.hasSatelliteTV,
            label: "Satellite TV/DSTV Wiring",
          },
          {
            name: "hasElevator",
            checked: house?.features?.[0]?.hasElevator,
            label: "Elevator",
          },
          {
            name: "hasBoysQuarters",
            checked: house?.features?.[0]?.hasBoysQuarters,
            label: "Boys Quarters",
          },
          {
            name: "hasSmartHomeTechnology",
            checked: house?.features?.[0]?.hasSmartHomeTechnology,
            label: "Smart Home Technology",
          },
        ],
        kitchenFeatures: [
          {
            name: "hasFullyEquippedKitcken",
            checked: house?.features?.[0]?.hasFullyEquippedKitcken,
            label: "Fully Equipped Kitchen",
          },
          {
            name: "hasModernAppliances",
            checked: house?.features?.[0]?.hasModernAppliances,
            label: "Modern Appliances (Refrigerators etc)",
          },
          {
            name: "hasGraniteCountertops",
            checked: house?.features?.[0]?.hasGraniteCountertops,
            label: "Granite Countertops",
          },
          {
            name: "hasBreakfastBar",
            checked: house?.features?.[0]?.hasBreakfastBar,
            label: "Breakfast Bar",
          },
          {
            name: "hasStorageRoom",
            checked: house?.features?.[0]?.hasStorageRoom,
            label: "Storage Room",
          },
          {
            name: "hasUpgradedBathroomFeatures",
            checked: house?.features?.[0]?.hasUpgradedBathroomFeatures,
            label: "Upgraded Bathroom Features",
          },
          {
            name: "hasSpaLikeFeatures",
            checked: house?.features?.[0]?.hasSpaLikeFeatures,
            label: "Spa-like Features",
          },
          {
            name: "hasTileOrMarbleFeatures",
            checked: house?.features?.[0]?.hasTileOrMarbleFeatures,
            label: "Tile or Marble Features",
          },
        ],
        livingRoomFeatures: [
          {
            name: "hasOpenFloorPlan",
            checked: house?.features?.[0]?.hasOpenFloorPlan,
            label: "Open Floor Plan",
          },
          {
            name: "hasLargeWindwos",
            checked: house?.features?.[0]?.hasLargeWindwos,
            label: "Large Windows for Natural Light",
          },
          {
            name: "hasBuiltInHouseTheater",
            checked: house?.features?.[0]?.hasBuiltInHouseTheater,
            label: "Built-in House Theater",
          },
        ],
        outdoorFeatures: [
          {
            name: "hasPrivateBackyard",
            checked: house?.features?.[0]?.hasPrivateBackyard,
            label: "Private Backyard",
          },
          {
            name: "hasPatioOrDarkSpace",
            checked: house?.features?.[0]?.hasPatioOrDarkSpace,
            label: "Patio or Deck Space",
          },
          {
            name: "hasLandscapedGarden",
            checked: house?.features?.[0]?.hasLandscapedGarden,
            label: "Landscaped Garden",
          },
          {
            name: "hasSwimmingPool",
            checked: house?.features?.[0]?.hasSwimmingPool,
            label: "Swimming Pool",
          },
        ],
        homeOfficeFeatures: [
          {
            name: "hasHomeOfficeSpace",
            checked: house?.features?.[0]?.hasHomeOfficeSpace,
            label: "Home Office Space",
          },
          {
            name: "hasBuiltInShelfOrBookSpace",
            checked: house?.features?.[0]?.hasBuiltInShelfOrBookSpace,
            label: "Built-in Shelf and Book Space",
          },
          {
            name: "hasAmpleNaturalLight",
            checked: house?.features?.[0]?.hasAmpleNaturalLight,
            label: "Ample Natural Light",
          },
        ],
        safetySecurityFeatures: [
          {
            name: "hasSecuritySystem",
            checked: house?.features?.[0]?.hasSecuritySystem,
            label: "Security System",
          },
          {
            name: "hasBulletProofDoors",
            checked: house?.features?.[0]?.hasBulletProofDoors,
            label: "Bullet Proof Doors",
          },
          {
            name: "hasGatedCompound",
            checked: house?.features?.[0]?.hasGatedCompound,
            label: "Gated Compound",
          },
          {
            name: "hasReinforcedDoorsAndWindows",
            checked: house?.features?.[0]?.hasReinforcedDoorsAndWindows,
            label: "Reinforced Doors & Windows",
          },
          {
            name: "hasGaurdedCommunity",
            checked: house?.features?.[0]?.hasGaurdedCommunity,
            label: "Gaurded Community",
          },
          {
            name: "hasUniformedSecurity",
            checked: house?.features?.[0]?.hasUniformedSecurity,
            label: "Uniformed Security",
          },
        ],
        parkingFeatures: [
          {
            name: "hasParkingGarage",
            checked: house?.features?.[0]?.hasParkingGarage,
            label: "Parking Garage",
          },
          {
            name: "hasDriveWaySpace",
            checked: house?.features?.[0]?.hasDriveWaySpace,
            label: "Driveway Space",
          },
          {
            name: "hasStreetParkingAvaliability",
            checked: house?.features?.[0]?.hasStreetParkingAvaliability,
            label: "Street Parking Availability",
          },
          {
            name: "hasPrivateParkingSpace",
            checked: house?.features?.[0]?.hasPrivateParkingSpace,
            label: "Private Parking Space",
          },
        ],
        utilitiesFeatures: [
          {
            name: "hasElectricity",
            checked: house?.features?.[0]?.hasElectricity,
            label: "Electricity",
          },
          {
            name: "hasBackupGenerator",
            checked: house?.features?.[0]?.hasBackupGenerator,
            label: "Backup Generator",
          },
          {
            name: "hasInverter",
            checked: house?.features?.[0]?.hasInverter,
            label: "Inverter",
          },
          {
            name: "hasBorehole",
            checked: house?.features?.[0]?.hasBorehole,
            label: "Borehole",
          },
          {
            name: "hasWaterBoard",
            checked: house?.features?.[0]?.hasWaterBoard,
            label: "Water Board",
          },
        ],
        communityFeatures: [
          {
            name: "hasProximityToSchools",
            checked: house?.features?.[0]?.hasProximityToSchools,
            label: "Proximity to Schools",
          },
          {
            name: "hasProximityToShoppingMalls",
            checked: house?.features?.[0]?.hasProximityToShoppingMalls,
            label: "Proximity to Shopping Malls",
          },
          {
            name: "hasProximityToSupermarkets",
            checked: house?.features?.[0]?.hasProximityToSupermarkets,
            label: "Proximity to Supper Markets",
          },
          {
            name: "hasNearByPublicTransportation",
            checked: house?.features?.[0]?.hasNearByPublicTransportation,
            label: "Near by Public Transportation",
          },
          {
            name: "hasAccessiblityViaBoltOrUber",
            checked: house?.features?.[0]?.hasAccessiblityViaBoltOrUber,
            label: "Accessible via Bolt/Uber",
          },
        ],
        petFeatures: [
          {
            name: "hasFencedBackyard",
            checked: house?.features?.[0]?.hasFencedBackyard,
            label: "Fenced Backyard",
          },
          {
            name: "hasPetFriendlyNeighbourhood",
            checked: house?.features?.[0]?.hasPetFriendlyNeighbourhood,
            label: "Pet Friendly Neighbourhood",
          },
          {
            name: "hasNearbyWalkingTrailsAndSidewalks",
            checked: house?.features?.[0]?.hasNearbyWalkingTrailsAndSidewalks,
            label: "Nearby Walking Trails & Sidewalks",
          },
        ],
      });
    }
  }, [house, reset]);

  useEffect(() => {
    if (features) {
      const checkAllFeaturesChecked = (categoryFeatures) => {
        return categoryFeatures?.every((feature) => feature.checked);
      };

      setAllGeneralFeatures(checkAllFeaturesChecked(features.generalFeatures));
      setAllKitchenFeatures(checkAllFeaturesChecked(features.kitchenFeatures));
      setAllLivingRoomFeatures(
        checkAllFeaturesChecked(features.livingRoomFeatures)
      );
      setAllOutdoorFeatures(checkAllFeaturesChecked(features.outdoorFeatures));
      setAllHomeOfficeFeatures(
        checkAllFeaturesChecked(features.homeOfficeFeatures)
      );
      setAllSafetySecurityFeatures(
        checkAllFeaturesChecked(features.safetySecurityFeatures)
      );
      setAllParkingFeatures(checkAllFeaturesChecked(features.parkingFeatures));
      setAllutilitiesFeatures(
        checkAllFeaturesChecked(features.utilitiesFeatures)
      );
      setAllCommunityFeatures(
        checkAllFeaturesChecked(features.communityFeatures)
      );
      setAllPetFeatures(checkAllFeaturesChecked(features.petFeatures));
    }
  }, [features]);

  useEffect(() => {
    const houseTypeName = houseTypes.find((x) => x.id === house?.type)?.name;
    setHouseTypeName(houseTypeName);
    setHouseType(house?.type);

    setCityId(house?.city?.id);
    setStateId(house?.city?.stateId);

    const houseCategoryName = categoryLists.find(
      (x) => x.id === house?.category
    )?.name;
    setHouseCategoryName(houseCategoryName);
    setHouseCategory(house?.category);

    const houseSegmentName = segmentLists.find(
      (x) => x.id === house?.segment
    )?.name;
    setHouseSegmentName(houseSegmentName);
    setHouseSegment(house?.segment);

    const statusName = houseStatusLists.find(
      (x) => x.id === house?.completionStatus
    )?.name;
    setCompletionStatusName(statusName);
    setCompletionStatus(house?.completionStatus);

    const ownershipDocumentName = ownershipDoc.find(
      (x) => x.id === house?.ownershipDocument
    )?.name;
    setOwnershipDocumentName(ownershipDocumentName);
    setOwnershipDocument(house?.ownershipDocument);

    const landTitleDocumentName = landTitleDocs.find(
      (x) => x.id === house?.landTitleDocument
    )?.name;
    setLandTitleDocumentName(landTitleDocumentName);
    setLandTitleDocument(house?.landTitleDocument);

    setCompletionDate(house?.completionDate);
    setCompletionPercentage(house?.completionPercentage);
  }, [house]);

  useEffect(() => {
    setPlanType("");
    setInterestType("");
  }, []);

  if (isLoading || isRefetching)
    return (
      <div style={{ height: "70vh" }}>
        {" "}
        <Spinner loadingText={"Loading House Details..."} />{" "}
      </div>
    );

  return (
    <div className="ps-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="text-uppercase">
            <span className=" text-success">
              {house.name ?? "NOT SPECIFIED"}
            </span>{" "}
            - {convertToTitleCase(houseType)}
          </h3>

          {/* Dropdownn for QR code  */}
          <div className="mb-2">
            <Dropdown>
              <Dropdown.Toggle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#F0F0F0",
                    borderRadius: "50%",
                    width: "3rem",
                    height: "3rem",
                    padding: "0",
                  }}
                >
                  <HiDotsHorizontal
                    className="fw-bold"
                    style={{ height: "2.8rem", color: "#707070" }}
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={downloadQRCode}>
                  {" "}
                  <Icons iconName={"generate_QR"} className={"mx-2"} /> Generate
                  QR Code
                </Dropdown.Item>
                <Dropdown.Item onClick={handleCopyPropertyLink}>
                  <Icons iconName={"copy_QR"} className={"mx-2"} /> Copy
                  Property Link
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowPlanTypeModal(true)}>
                  <Icons iconName={"plans"} className={"me-2 plan_icons"} />
                  Create Plan
                </Dropdown.Item>
                {house.houseMortgagePlans?.length > 0 && (
                  <Dropdown.Item
                    onClick={() =>
                      navigate(`/properties/house/plans/${houseId}`)
                    }
                  >
                    <Icons iconName={"plans"} className={"me-2 plan_icons"} />
                    View Plans
                  </Dropdown.Item>
                )}
                {accessDeleteHouse?.value && (
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => setShowModal(true)}
                  >
                    {" "}
                    <Icons iconName={"delete2"} className={"mx-2"} /> Delete
                    House
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="">
          <div className="row">
            <div className="col">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: "248px",
                  borderRadius: "8px",
                  border: "1px solid #335f32",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <span className="plus-icon d-flex justify-content-center align-items-center">
                  <Icons iconName={"plus"} />
                </span>
                <p className="fw-bold text-success uppercase mt-3">
                  ADD PROPERTY IMAGES
                </p>
              </div>
            </div>

            {/* Display First Three Images */}
            {imagesToDisplay.slice(0, 3)?.map((image) => (
              <div
                className="col house_image_wrapper position-relative"
                key={image.name}
                style={{
                  backgroundImage: `url(${image.document})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="img_overlay position-absolute"></div>
                <div onClick={() => handleImageClick(image)}>
                  <p className="img_open">
                    <span className="img_open-icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <div className="img_footer position-absolute">
                  <div className="mt-4 mb-3">
                    <div>
                      <p className="img_name m-0">{image.name}</p>
                    </div>
                    {image.type && (
                      <p className="img_type m-0 mt-2">
                        {convertToTitleCase(image.type)}
                      </p>
                    )}
                  </div>
                  <div className="img_delete_icon">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <input
            type="file"
            className="input-image"
            ref={hiddenFileInputRef}
            alt=""
            accept="image/*"
            onChange={handleChange}
            multiple
          />
        </div>

        {/* Thumbnails Section */}
        <div className="col h-25 thumbs-wrapper mt-3 mb-4">
          {imagesToDisplay.length > 3 &&
            imagesToDisplay.slice(3).map((image, index) => (
              <div className="thumb position-relative d-flex flex-column me-2">
                <div
                  className="position-relative w-full h-full thumb_img"
                  style={{
                    backgroundImage: `url(${image.document})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="thumb_img_overlay position-absolute"></div>
                  <p
                    className="thumb_open"
                    onClick={() => handleImageClick(image)}
                  >
                    <span className="thumb_open_icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <p className="delete_icon_thumbs">
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    iconWidth={"15px"}
                    width={"25px"}
                    height={"29px"}
                    type={"button"}
                    onBtnClick={() => openDeleteModal(image)}
                  />
                </p>
                <p
                  className="my-0 text-success"
                  title={image.name}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {image.name}
                </p>
              </div>
            ))}
        </div>

        <div className="row mt-4">
          <div className="col-md-4 mb-2">
            <FormInput
              required
              labelName={"Name"}
              placeholder={"Name"}
              inputDescription
              inputDescriptionText={
                "If your organization doesn’t name properties, you can name the property with the house type, the number of beds, and the number of baths. E.g 2bed 2baths Semi Detached. If you have a property name, for example “Prime Court”, you can name it with the property name."
              }
              register={register("name")}
              name="name"
            />
          </div>

          <div className="col-md-4 mb-2">
            <CustomDropdown
              labelName={"House Type"}
              placeholder={"Select house type"}
              value={convertToTitleCase(houseType)}
              options={houseTypes}
              onChange={(e) => setHouseType(e.target.value)}
            />
          </div>

          {house?.estate ? (
            <>
              <div className="col-md-4 mb-2">
                <FormInput
                  required
                  placeholder={"Select a state"}
                  labelName={"State"}
                  readOnly
                  backgroundcolor={colors.grey}
                  value={convertToTitleCase(house?.city?.stateId)}
                />
              </div>
              <div className="col-md-4 mb-2">
                <FormInput
                  required
                  labelName={"City"}
                  readOnly
                  backgroundcolor={colors.grey}
                  value={convertToTitleCase(house?.city?.name)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4 mb-2">
                <CustomDropdown
                  placeholder={"Select a state"}
                  labelName={"State"}
                  value={convertToTitleCase(stateId)}
                  options={states}
                  onChange={(e) => {
                    setStateId(e.target.value);
                    refetch();
                  }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <CustomDropdown
                  placeholder={house?.city?.name}
                  labelName={"City"}
                  options={city}
                  onChange={(e) => setCityId(e.target.value)}
                  isLoading={cityLoading}
                  disabled={cityLoading}
                />
              </div>
            </>
          )}

          <div
            className="col-4 mb-2"
            onBlur={(e) =>
              setValue(
                "price",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(house.price)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              required
              labelName={"Price"}
              register={register("price")}
              name="price"
              placeholder={"How much is this house selling for?"}
            />
          </div>
          <div
            className="mb-2 col-md-4 col-sm-12"
            onBlur={(e) => {
              const youtubeLink = e.target.value.trim(); // Trim whitespace
              if (!youtubeLink) return; // If the field is empty, do nothing

              const pattern = /watch\?v=([a-zA-Z0-9_-]+)/;
              const match = youtubeLink.match(pattern);
              const embedPattern =
                /https:\/\/www.youtube.com\/embed\/([a-zA-Z0-9_-]+)/;
              const embedMatch = youtubeLink.match(embedPattern);

              if (embedMatch) {
                // Link is already in embed format, no need for further processing
                return;
              }

              if (match) {
                setValue(
                  "videoUrl",
                  `https://www.youtube.com/embed/${match[1]}`
                );
              } else {
                toast.warning(
                  "Invalid YouTube link. Please provide a valid link."
                );
                setValue("videoUrl", "");
              }
            }}
          >
            <FormInput
              labelName={"Video URL"}
              name={"videoUrl"}
              type={"text"}
              placeholder={"Add a YouTube video of the property"}
              register={register("videoUrl")}
            />
          </div>
          {house?.estate ? (
            <div className="col-md-4 mb-2">
              <FormInput
                required
                labelName={"Address"}
                readOnly
                backgroundcolor={colors.grey}
                value={convertToTitleCase(house?.estate?.address)}
                // onChange={(e) => setCityId(e.target.value)}
              />
            </div>
          ) : (
            <div className="col-md-4 mb-2">
              <FormInput
                required
                labelName={"Address"}
                type={"text"}
                placeholder={"No 123 Joseph Howe Drive"}
                register={register("address")}
                name="address"
              />
            </div>
          )}

          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"Popular Landmark"}
              type={"text"}
              placeholder={"NNPC Fueling Station"}
              register={register("landmark")}
              name="landmark"
            />
          </div>
          <div className="col-md-4 mb-2">
            <FormInput
              // required
              labelName={"Units Left"}
              type={"number"}
              placeholder={"Select unit"}
              register={register("units")}
            />
          </div>
          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"3D Tour Link"}
              // required={true}
              placeholder={"Add a 3D tour link"}
              register={register("threeDTourLink")}
            />
          </div>
          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"Virtual Tour Link"}
              // required={true}
              placeholder={"Add a virtual tour link"}
              register={register("virtualTourLink")}
            />
          </div>
          <div className="col-md-4 mb-2">
            <CustomDropdown
              labelName={"Segment"}
              required
              placeholder={"Select"}
              value={houseSegmentName}
              options={segmentLists}
              onChange={(e) => {
                let id = e.target.value;
                if (id) {
                  const segmentName = segmentLists.find(
                    (x) => x.id === id
                  ).name;
                  setHouseSegmentName(segmentName);
                  setHouseSegment(id);
                }
              }}
            />
          </div>
          <div className="col-md-4 mb-2">
            <CustomDropdown
              required
              labelName={"Category"}
              placeholder={"Select"}
              value={houseCategoryName}
              options={categoryLists}
              onChange={(e) => {
                let id = e.target.value;
                if (id) {
                  const categoryName = categoryLists.find(
                    (x) => x.id === id
                  ).name;
                  setHouseCategoryName(categoryName);
                  setHouseCategory(id);
                }
              }}
            />
          </div>
          <div className="col-md-4 mb-2">
            <CustomDropdown
              required
              labelName={"Completion Status"}
              placeholder={"Select Status"}
              value={completionStatusName}
              options={houseStatusLists}
              onChange={(e) => {
                let id = e.target.value;
                if (id) {
                  const statusName = houseStatusLists.find(
                    (x) => x.id === id
                  ).name;
                  setCompletionStatusName(statusName);
                  setCompletionStatus(id);
                }
              }}
            />
          </div>
          {completionStatus === "UNDER_CONSTRUCTION" && (
            <div className="col-md-4 mb-2">
              <FormInput
                required
                type={"date"}
                // required
                labelName={"Completion Date"}
                placeholder={"When will it be completed?"}
                register={register("completionDate")}
              />
            </div>
          )}
          {/* {completionStatus === status[2].id && (
            <div className="col-md-4 mb-2">
              <CustomDropdown
                labelName={"Off Plan Sales Percentage"}
                placeholder={"Select sales percentage"}
                options={salesPercentage}
                value={completionPercentage}
                onChange={(e) => setCompletionPercentage(e.target.value)}
              />
            </div>
          )} */}
          <div className="col-md-4 mb-2">
            <CustomDropdown
              required
              labelName={"Land Title Document"}
              placeholder={"Select"}
              value={landTitleDocumentName}
              options={landTitleDocs}
              onChange={(e) => {
                let id = e.target.value;
                if (id) {
                  const landTitleDocumentName = landTitleDocs.find(
                    (x) => x.id === id
                  ).name;
                  setLandTitleDocumentName(landTitleDocumentName);
                  setLandTitleDocument(id);
                }
              }}
            />
          </div>

          <div className="col-md-4 mb-2">
            <CustomDropdown
              required
              labelName={"Ownership Document"}
              placeholder={"Select"}
              value={ownershipDocumentName}
              options={ownershipDoc}
              onChange={(e) => {
                let id = e.target.value;
                if (id) {
                  const ownershipDocumentName = ownershipDoc.find(
                    (x) => x.id === id
                  ).name;
                  setOwnershipDocumentName(ownershipDocumentName);
                  setOwnershipDocument(id);
                }
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <MultitextInput
              required
              label={"House Description"}
              name={"description"}
              register={register("description")}
            />
          </div>
          <div className="col-md-4">
            <MultitextInput
              label={"Unique Selling Proposition"}
              name={"uniqueSellingProposition"}
              register={register("uniqueSellingProposition")}
              placeholder={
                "What makes this house special and different from others..."
              }
            />
          </div>
          <div className="col-md-4">
            <MultitextInput
              label={"Value and Investment"}
              name={"valueAndInvestment"}
              register={register("valueAndInvestment")}
              placeholder={
                "Why is this house a good investment and how would you advise customers to use it to deliver outstanding returns..."
              }
            />
          </div>
        </div>

        <div className="row pt-3">
          <h2 className="mt-4">Specifications and Amenities</h2>
          <div className="row m-0 mt-2 p-0">
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                required
                labelName={"Bedrooms"}
                width={"100%"}
                min={"0"}
                type={"number"}
                placeholder={"How many bedrooms?"}
                register={register("bedrooms")}
                name="bedrooms"
              />
            </div>
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                required
                labelName={"Guest Toilets"}
                width={"100%"}
                type={"number"}
                // required
                placeholder={"How many guest toilets?"}
                register={register("guestToilets")}
                name="guestToilets"
              />
            </div>
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                required
                labelName={"Baths"}
                width={"100%"}
                min={"0"}
                type={"number"}
                placeholder={"How many baths?"}
                register={register("bathrooms")}
                name="bathrooms"
              />
            </div>
            <div
              className="col-sm-6 col-md-4 col-xxl-3"
              onBlur={(e) =>
                setValue(
                  "squareMeters",
                  !onlyNumbers(e.target.value)
                    ? formattedPrice(house.squareMeters)
                    : formattedPrice(e.target.value)
                )
              }
            >
              <FormInput
                required
                labelName={"Square Meters"}
                width={"100%"}
                placeholder={"Enter square meters"}
                register={register("squareMeters")}
                name="squareMeters"
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"generalFeat"} />{" "}
              <span className="me-4 ps-2">General Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allGeneralFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allGeneralFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllGeneralFeatures(!allGeneralFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "generalFeatures",
                    allGeneralFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.generalFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllGeneralFeatures(false);
                  handleIndividualFeatureToggle("generalFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"kitchenFeat"} />{" "}
              <span className="me-4 ps-2">Kitchen Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allKitchenFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allKitchenFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllKitchenFeatures(!allKitchenFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "kitchenFeatures",
                    allKitchenFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.kitchenFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllKitchenFeatures(false);
                  handleIndividualFeatureToggle("kitchenFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"livingRoomFeat"} />{" "}
              <span className="me-4 ps-2">Living Room Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allLivingRoomFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allLivingRoomFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllLivingRoomFeatures(!allLivingRoomFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "livingRoomFeatures",
                    allLivingRoomFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.livingRoomFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllLivingRoomFeatures(false);
                  handleIndividualFeatureToggle(
                    "livingRoomFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"OutdoorFeat"} />{" "}
              <span className="me-4 ps-2">Outdoor Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allOutdoorFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allOutdoorFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllOutdoorFeatures(!allOutdoorFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "outdoorFeatures",
                    allOutdoorFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.outdoorFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllOutdoorFeatures(false);
                  handleIndividualFeatureToggle("outdoorFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"homeOfficeFeat"} />{" "}
              <span className="me-4 ps-2">Home Office Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allHomeOfficeFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allHomeOfficeFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllHomeOfficeFeatures(!allHomeOfficeFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "homeOfficeFeatures",
                    allHomeOfficeFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.homeOfficeFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllHomeOfficeFeatures(false);
                  handleIndividualFeatureToggle(
                    "homeOfficeFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"securityFeat"} />{" "}
              <span className="me-4 ps-2">Safety & Security Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allSafetySecurityFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allSafetySecurityFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllSafetySecurityFeatures(!allSafetySecurityFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "safetySecurityFeatures",
                    allSafetySecurityFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.safetySecurityFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllSafetySecurityFeatures(false);
                  handleIndividualFeatureToggle(
                    "safetySecurityFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"parkingFeat"} />{" "}
              <span className="me-4 ps-2">Parking Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allParkingFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allParkingFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllParkingFeatures(!allParkingFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "parkingFeatures",
                    allParkingFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.parkingFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllParkingFeatures(false);
                  handleIndividualFeatureToggle("parkingFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"utilsFeat"} />{" "}
              <span className="me-4 ps-2">Utilities</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allutilitiesFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allutilitiesFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllutilitiesFeatures(!allutilitiesFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "utilitiesFeatures",
                    allutilitiesFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.utilitiesFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllutilitiesFeatures(false);
                  handleIndividualFeatureToggle("utilitiesFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"communityFeat"} />{" "}
              <span className="me-4 ps-2">Community Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allCommunityFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allCommunityFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllCommunityFeatures(!allCommunityFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "communityFeatures",
                    allCommunityFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.communityFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllCommunityFeatures(false);
                  handleIndividualFeatureToggle("communityFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 me-4 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"petFeat"} />{" "}
              <span className="me-4 ps-2">Pet Features</span>
            </h5>
            <div>
              <CheckInput
                name="toggleAll"
                checked={allPetFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllPetFeatures(!allPetFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "petFeatures",
                    allPetFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.petFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllPetFeatures(false);
                  handleIndividualFeatureToggle("petFeatures", spec.name);
                }}
              />
            </div>
          ))}
        </div>

        {accessUpdateHouse?.value && (
          <FooterButtons
            largeLoader={updateHouse.isLoading}
            loading={updateHouse.isLoading}
            disabled={updateHouse.isLoading}
            cancelBtn={() => navigate("/properties")}
            // handleClick={(e) => e.preventDefault}
            height={"100px"}
          />
        )}
      </form>

      {/* SELECT PLAN TYPE  */}
      <Modal
        show={showPlanTypeModal}
        closeModal={() => {
          setPlanType("");
          setInterestType("");
          setShowPlanTypeModal(false);
        }}
        title={
          showSelectInterestOrCompoundType
            ? "Select Interest Type"
            : "Select Plan Type"
        }
        subTitle={
          showSelectInterestOrCompoundType
            ? "What type of interest rate does this mortgage plan use?"
            : "What kind of plan do you want to create? Select One"
        }
        confirmModal={() => {
          if (
            removeUnderscore(planType) === "MORTGAGEPLAN" &&
            !showSelectInterestOrCompoundType
          ) {
            return setShowSelectInterestOrCompoundType(true);
          }
          navigate(`/properties/house/plans/create/${houseId}`);
          setShowPlanTypeModal(false);
        }}
        closeModalIcon={true}
        backBtn={showSelectInterestOrCompoundType}
        onBtnClick={() => {
          setPlanType(null);
          handleSelectInterestType(null);
          setShowSelectInterestOrCompoundType(false);
        }}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Continue"}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <>
          {showSelectInterestOrCompoundType ? (
            <div className="select_interest_type d-md-flex mt-5">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Simple Interest or Markup Rate"}
                  iconName={"simpleInterest"}
                  description={
                    "This is a simple markup on the property price. For example, if a house is ₦50 Million with a 10% simple interest or markup rate, they’ll repay ₦50 Million + ₦5 Million (the 10% extra markup rate)."
                  }
                  onChange={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  onClick={() => handleSelectInterestType("SIMPLE_INTEREST")}
                  selected={interestType === "SIMPLE_INTEREST"}
                />
              </div>

              <p className="text-center">OR</p>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Compound Interest"}
                  iconName={"compoundInterest"}
                  description={
                    "This is a compounding interest rate which is used in most traditional mortgages. Compounding rates accrue interest on a monthly basis. For example, if a house is ₦50 Million with a 10% compund interest, it means the 10% interest rate will compound monthly. We calculate the compund rate automatically and attach it to the customer’s repayment schedule."
                  }
                  onChange={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  onClick={() => handleSelectInterestType("COMPOUND_INTEREST")}
                  selected={interestType === "COMPOUND_INTEREST"}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 mt-4">
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Outright Plan"}
                  iconName={"outrightPlan"}
                  description={
                    "With outright plans, customers pay 100% of the purchase price of the property in one bulk payment. There is no repayment necessary."
                  }
                  onChange={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  onClick={() => handleSelectPlanType("OUTRIGHT_PLAN")}
                  selected={planType === "OUTRIGHT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"Payment Plan"}
                  iconName={"paymentPlan"}
                  description={
                    " Payment plans allow customers pay a downpayment then spread the  remaining payment over a fixed time period."
                  }
                  onChange={() => handleSelectPlanType("PAYMENT_PLAN")}
                  onClick={() => handleSelectPlanType("PAYMENT_PLAN")}
                  selected={planType === "PAYMENT_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" FMBN Rent To Own"}
                  iconName={"FMBN"}
                  description={
                    " This is a plan offered by the Federal Mortgage Bank of Nigeria.vIf you have partnered with FMBN to sell properties through their Rent To Own Plan, you may use this plan to boost your sales and collect applications and manage sales and documentation. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("RENT_TO_OWN")}
                  onClick={() => handleSelectPlanType("RENT_TO_OWN")}
                  selected={planType === "RENT_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={" Mortgage Plan"}
                  iconName={"mortgagePlan"}
                  description={
                    " This is a plan that attracts interest payments from customers and is paid over several years. Interest on mortgages can be simple or compond interest. We accomodate for both interest types."
                  }
                  cursor={"pointer"}
                  onChange={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  onClick={() => handleSelectPlanType("MORTGAGE_PLAN")}
                  selected={planType === "MORTGAGE_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"  National Housing Fund"}
                  iconName={"nationalHousingFund"}
                  description={
                    "This is a plan from the Federal Mortgage Bank of Nigeria. If you are authorized by FMBN to sell NHF mortgages, Rent To Own you can do so through here. However, you will be responsible for processing the customer’s application to FMBN."
                  }
                  onChange={() => handleSelectPlanType("NHF_PLAN")}
                  onClick={() => handleSelectPlanType("NHF_PLAN")}
                  selected={planType === "NHF_PLAN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"FMF Help To Own."}
                  iconName={"FMBN"}
                  description={
                    "This is a Family Homes Fund mortgage plan that offers loans of up to ₦35 Million Naira for customers to buy a house at 14% interest for up to 20 years."
                  }
                  onChange={() => handleSelectPlanType("HELP_TO_OWN")}
                  onClick={() => handleSelectPlanType("HELP_TO_OWN")}
                  selected={planType === "HELP_TO_OWN"}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <PlanTypeCard
                  title={"M-REIF"}
                  iconName={"paymentPlan"}
                  description={
                    "This is a mortgage plan by the Ministry of Finance Incorporated Ltd that provides loans for Nigerians at home & abroad offering interest rates of 12%,  and a maximum loan amount of ₦200 millon."
                  }
                  onChange={() => {
                    if (house?.price > 200000000) {
                      return;
                    }
                    handleSelectPlanType("MOFI_REAL_ESTATE_INVESTMENT_FUND");
                  }}
                  onClick={() => {
                    if (house?.price > 200000000) {
                      return toast.error(
                        "You cannot apply for the 'MOFI - Real Estate Investment Fund (M-REIF)' plan if the house price is higher than ₦200,000,000."
                      );
                    }
                    handleSelectPlanType("MOFI_REAL_ESTATE_INVESTMENT_FUND");
                  }}
                  selected={planType === "MOFI_REAL_ESTATE_INVESTMENT_FUND"}
                />
              </div>
            </div>
          )}
        </>
      </Modal>

      {/* DELETE IMAGE MODAL  */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Image"}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        confirmModal={() => handleRemoveImages()}
        cancelModal={() => setShowDeleteModal(false)}
        subTitle={"Are you sure you want to delete this image?"}
      >
        {/* <p className="text-center">
          Are you sure you want to delete this image?
        </p> */}
      </Modal>

      <Modal
        show={showImageModal}
        closeModal={() => setShowImageModal(false)}
        title="Name Uploaded Image"
        confirmButtonLabel="Save"
        crossLine={false}
        confirmModal={saveImageName}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3">
          <p>
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <div
                style={{
                  maxHeight: "20rem",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={selectedImage.document}
                  alt={selectedImage.name}
                  className="img-fluid w-100 h-100"
                />
              </div>

              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", bottom: "1rem", left: "0.5rem" }}
              >
                <span
                  className="bg-white text-success px-2 py-1 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mx-3 mt-4 mb-0">
          <CustomDropdown
            labelName={"Image Type"}
            width={"100%"}
            required
            placeholder={"Select"}
            options={imageTypes}
            value={convertToTitleCase(editableImageType)}
            onChange={(e) => setEditableImageType(e.target.value)}
          />
        </div>
        <div className="mx-3 mt-2 mb-0">
          <FormInput
            required
            labelName={"Image Name (name the part of this house)"}
            value={editableImageName}
            onChange={(name, value) => setEditableImageName(value)}
          />
        </div>
      </Modal>

      <Modal
        show={showModal}
        title={"Delete House"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        isBtnLoading={deleteHouse.isLoading}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          deleteHouse.mutate(null, {
            onSuccess: () => {
              setShowModal(false);
              navigate("/properties");
            },
          })
        }
      >
        <div className="text-center">
          Are you sure you want to delete {convertToTitleCase(houseType)} -{" "}
          {house?.address}?{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>
    </div>
  );
};

export default House;
