import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

const useGetEstateHouses = (estateId, page) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["house", estateId, page],
    queryFn: () =>
      getRequest({ url: `/developer/estate/${estateId}/get-houses?pageNumber=${page}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });
};

export default useGetEstateHouses;
