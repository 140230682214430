import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "./useAxiosBase";

const useGetCity = () => {
  const { getRequest } = useAxiosBase();

  const useCity = useMutation({
    mutationFn: (stateId) =>
      getRequest({
        url: `/public/city/${stateId}/get-all`,
        params: {
          PageSize: 1000,
          PageNumber: 1,
        },
      }).then((res) => {
        return res.data?.value?.value?.data?.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
      }),
    staleTime: 50000,
  });

  return { useCity };
};

export default useGetCity;
