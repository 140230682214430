import { Dropdown } from "react-bootstrap";
import CellSelector from "../../../../Components/Common/CellSelector";
import { convertToTitleCase, stripHtmlTags } from "../../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import { wrap } from "lodash";

export const columns = (handleAction, loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        NAME
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.type === "YOUTUBE_VIDEO" ? "Video" : "Image"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DESCRIPTION
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{stripHtmlTags(row?.description)}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE CREATED
      </p>
    ),
    sortable: true,
    grow: 0.5,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        ACTION{" "}
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-${row.id}`}>
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        {!loading && (
          <Dropdown.Menu>
            <Dropdown.Item
              className="text-danger"
              onClick={() => handleAction(row.id, "deleteConstructionUpdate")}
            >
              Delete Construction Update
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
    allowOverflow: true,
  },
];
