import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../Components/Common/Button";
import {
  colors,
  formattedPrice,
  onlyNumbers,
  getNumber,
  validateNumberInput,
} from "../../../utils/constants";
import FormInput from "../../../Components/Common/FormInput";
import "../House/createHouse.css";
import Dropdown from "../../../Components/Common/Dropdown";
import { useNav } from "../../../hooks/useNav";
import Icons from "../../../Components/Common/Icons";
import { fileExtension, getBase64Image } from "../../../utils/imageUtils";
import { city, state } from "../../../services/publicService";
import { toast } from "react-toastify";
import {
  bedrooms,
  houseTypes,
  houseStatusLists,
  categoryLists,
  segmentLists,
  landTitleDocs,
  ownershipDoc,
  salesPercentage,
  imageTypes,
} from "../../../utils/mockData";
import { debounce } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import houseModel from "../../../Entities/House/house";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Modal from "../../../Components/Common/Modal";
import useCity from "../../../hooks/useCity";
import useAllStates from "../../../hooks/useAllStates";
import { useMutation, useQuery } from "@tanstack/react-query";

import useAxiosBase from "../../../hooks/useAxiosBase";
import { addDays } from "../constant";
import FooterButtons from "../../../Components/Common/FooterButtons";
import MultitextInput from "../../../Components/Common/MultitextInput";
import useEstate from "../../../hooks/useEstate";
import Spinner from "../../../Components/Spinner";
import useUploadImages from "./hooks/useUploadImages";
import { convertToTitleCase } from "../../../utils/constants";
import { FaRegCircleQuestion } from "react-icons/fa6";
import CheckInput from "../../../Components/Common/CheckInput";

const CreateHouse = () => {
  const { authUser } = useAuth();

  const params = useParams();
  const estateId = params?.estateId;

  const [responseID, setResponseID] = useState();
  const { uploadingHouseImages } = useUploadImages(responseID);

  const {
    data: estateCollection,
    error,
    isLoading,
    refetch: refetchEstate,
  } = useEstate();

  const [estateDetails, setEstateDetails] = useState("");

  const [estateName, setEstateName] = useState("");

  const yes = [{ id: "yes", name: "Yes" }];

  const hadnleSingleEstate = () => {
    if (estateCollection) {
      const selectedEstate = estateCollection.find(
        (estate) => estate.id === estateId
      );
      setEstateDetails(selectedEstate);
      setEstateName(selectedEstate?.name);
    }
  };

  useEffect(() => {
    hadnleSingleEstate();
  }, [estateCollection, estateId]);

  useNav({
    hasBackButton: true,
    mainTitle: `Create a House ${
      estateName ? `- Adding to ${estateName}` : ""
    }`,
  });

  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [cityId, setCityId] = useState();
  const [cityError, setCityError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(houseModel);
  const [newImageName, setNewImageName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState(imageTypes[0]?.id);
  const [completionStatus, setCompletionStatus] = useState();

  const [showReplaceOrRemoveImageModal, setShowReplaceOrRemoveImageModal] =
    useState(false);
  const [replaceImage, setReplaceImage] = useState(false);

  const estate_image_ref = useRef();
  const refArray = React.useRef([]);

  const saveImageName = debounce(() => {
    if (!editableImageName.trim()) {
      toast.error("Please input a valid property name.");
      return;
    }

    if (!editableImageType.trim()) {
      toast.error("Please select a valid image type.");
      return;
    }

    if (selectedImage) {
      // Map through the imagesToDisplay array to update the name and type of the selected image
      const updatedImages = imagesToDisplay.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );
      const updatedImageObject = imageObject.map((img) =>
        img.name === selectedImage.name
          ? { ...img, name: editableImageName, type: editableImageType }
          : img
      );

      setImagesToDisplay(updatedImages);
      setImageObject(updatedImageObject);

      setShowImageModal(false);
      setSelectedImage(null); // Reset selected image
      setEditableImageName(""); // Reset editable name
      setEditableImageType(imageTypes[0]?.id); // Reset editable type
    }
  }, 300);

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if the file size is greater than 2MB
    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size shouldn't be more than 2MB.");

      // Reset file input value
      event.target.value = null;
      return;
    }

    // Check if an image with the same name already exists
    if (imagesToDisplay.some((img) => img.name === file.name)) {
      toast.error(`An image with the name ${file.name} already exists.`);

      // Reset file input value
      event.target.value = null;
      return;
    }

    if (replaceImage) {
      // Existing logic to replace an image in the thumbnails section
      let images = [...imagesToDisplay];
      let newFormData = { ...formData, images: [...formData.images] };
      const objectURL = URL.createObjectURL(file);
      images[imageToReplaceOrRemoveIndex] = {
        image: objectURL,
        name: file.name,
      };
      setShowImageModal(true);
      setNewImageName(file.name);
      setImagesToDisplay(images);
      const base64 = await getBase64Image(file);
      newFormData.images[imageToReplaceOrRemoveIndex] = {
        base64: base64,
        optionId: "HOUSE_IMAGE",
        extension: fileExtension(file),
        name: file.name,
      };

      setFormData(newFormData);
      setShowModal(false);
      setReplaceImage(false);
    } else if (showImageModal && selectedImage) {
      setShowImageModal(true);
      setEditableImageName(file.name);
      // Logic to replace the image displayed in the modal
      const newImageSrc = URL.createObjectURL(file);
      setSelectedImage({
        ...selectedImage,
        image: newImageSrc,
        name: file.name,
      });

      setImagesToDisplay(
        imagesToDisplay.map((img) =>
          img.name === selectedImage.name
            ? { ...img, image: newImageSrc, name: file.name }
            : img
        )
      );

      setImageObject(
        imageObject.map((img) =>
          img.name === selectedImage.name ? { ...img, name: file.name } : img
        )
      );

      // Reset file input value
      event.target.value = null;
      return;
    } else {
      // Logic to add a new image
      const newImage = {
        image: URL.createObjectURL(file),
        name: file.name,
      };

      setShowImageModal(true);
      setEditableImageName(file.name);
      setSelectedImage(newImage);

      setImagesToDisplay([newImage, ...imagesToDisplay]);
      URL.revokeObjectURL(file);
      const base64 = await getBase64Image(file);
      const newObjectImage = {
        base64: base64,
        name: file.name,
        optionId: "ESTATE_IMAGE",
        description: "",
        extension: fileExtension(file),
      };
      setImageObject((prevImages) => [newObjectImage, ...prevImages]);

      // Reset file input value
      event.target.value = null;
      return;
    }
    // Reset file input value
    event.target.value = null;
    return;
  };

  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    } else {
      console.error("The file input is not yet available.");
    }
  };

  const handleChangeImageName = (index) => {
    if (imagesToDisplay[index]?.name !== newImageName) {
      let newImages = [...imagesToDisplay];
      let newFormData = { ...formData, images: [...formData.images] };
      newImages[index].name = newImageName;
      newFormData.images[index].name = newImageName;
      setImagesToDisplay(newImages);
      setFormData(newFormData);
    }
    setShowModal(false);
    setImageToReplaceOrRemoveIndex(null);
    setImageToReplaceOrRemove(null);
    setReplaceImage(false);
  };

  const [stateId, setStateId] = useState();

  const { data: states } = useAllStates({
    onSuccess: (data) => {
      const sortedStates = data?.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setStateId(sortedStates[0]?.id);
      refetch();
    },
  });

  const {
    refetch,
    data: city,
    isLoading: isCityLoading,
  } = useCity({ stateId });

  const getSelectedFeatures = () => {
    const selectedFeatures = {};

    Object.keys(features).forEach((group) => {
      features[group].forEach((feature) => {
        if (feature.checked) {
          selectedFeatures[feature.name] = true;
        }
      });
    });

    return selectedFeatures;
  };

  // CTREATE HOUSE
  const createHouse = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: "/developer/house/create",
        data: data,
      }).then((res) => {
        if ([400, 401, 413].includes(res?.response?.data?.statusCode)) {
          toast.error(res?.response?.data?.value?.message);
          return;
        }
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          setResponseID(res?.data?.value?.value?.id);
          return;
        }
        return res.data;
      }),
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (imageObject?.length <= 0) {
      toast.error("Please select an image");
      return;
    }

    const selectedFeatures = getSelectedFeatures();

    setLoading(true);

    if (authUser) {
      formData["organizationId"] = authUser?.user?.organizationId;
    }
    if (estateId) {
      formData["estateId"] = estateId;
    }

    if (estateDetails) {
      formData["address"] = estateDetails.address;
      formData["cityId"] = estateDetails?.city?.id;
      formData["landmark"] = estateDetails?.landmark;
    }

    formData["feature"] = selectedFeatures;
    formData["price"] = parseFloat(
      formData["price"].toString().replace(/[^0-9.-]+/g, "")
    );
    formData["units"] = formData["units"]
      ? parseFloat(formData["units"].toString().replace(/[^0-9.-]+/g, ""))
      : 0;
    formData["bedrooms"] = formData["bedrooms"]
      ? parseFloat(formData["bedrooms"].toString().replace(/[^0-9.-]+/g, ""))
      : 0;
    formData["guestToilets"] = formData["guestToilets"]
      ? parseFloat(
          formData["guestToilets"].toString().replace(/[^0-9.-]+/g, "")
        )
      : 0;
    formData["bathrooms"] = formData["bathrooms"]
      ? parseFloat(formData["bathrooms"].toString().replace(/[^0-9.-]+/g, ""))
      : 0;
    formData["squareMeters"] = formData["squareMeters"]
      ? parseFloat(
          formData["squareMeters"].toString().replace(/[^0-9.-]+/g, "")
        )
      : 0;

    formData.ownerType = "DEVELOPER";
    formData.images = [];
    // console.log("FormData", formData);
    createHouse.mutate(formData);
  };

  const handleImageUploadFunction = () => {
    if (responseID) {
      imageObject?.map((image) => {
        uploadingHouseImages.mutate(image);
      });

      navigate("/properties");
    }
  };

  useEffect(() => {
    handleImageUploadFunction();
  }, [responseID]);

  const [imageToReplaceOrRemove, setImageToReplaceOrRemove] = useState();
  const [imageToReplaceOrRemoveIndex, setImageToReplaceOrRemoveIndex] =
    useState();

  const handleReplaceOrRemoveOption = (image, index) => {
    setImageToReplaceOrRemove(image);
    setImageToReplaceOrRemoveIndex(index);
    setShowReplaceOrRemoveImageModal(true);
  };

  const handleRemoveImage = (image, index) => {
    let newImages = [...imagesToDisplay];
    let newFormData = { ...formData, images: [...formData.images] };
    newImages = newImages.filter((img) => img !== image);
    newFormData.images = newFormData.images.filter((img) => img !== image);
    setImagesToDisplay(newImages);
    setFormData(newFormData);
    setImageToReplaceOrRemove(null);
    setImageToReplaceOrRemoveIndex(null);
    setShowReplaceOrRemoveImageModal(false);
  };

  const handleReplaceImage = (image, index) => {
    estate_image_ref.current.click();
    setImageToReplaceOrRemove(image);
    setImageToReplaceOrRemoveIndex(index);
    setReplaceImage(true);
    setShowReplaceOrRemoveImageModal(false);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  const handleDeleteImage = (imageName) => {
    setImagesToDisplay(
      imagesToDisplay.filter((image) => image.name !== imageName)
    );
  };

  const hiddenFileInputRef = useRef(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (imageToDelete) {
      setImagesToDisplay(
        imagesToDisplay.filter((img) => img !== imageToDelete)
      );

      setImageObject((prevImages) =>
        prevImages.filter((img) => img.name !== imageToDelete.name)
      );
      setImageToDelete(null);
    }
    setShowDeleteModal(false);
  };

  const [allGeneralFeatures, setAllGeneralFeatures] = useState(false);
  const [allKitchenFeatures, setAllKitchenFeatures] = useState(false);
  const [allLivingRoomFeatures, setAllLivingRoomFeatures] = useState(false);
  const [allOutdoorFeatures, setAllOutdoorFeatures] = useState(false);
  const [allHomeOfficeFeatures, setAllHomeOfficeFeatures] = useState(false);
  const [allSafetySecurityFeatures, setAllSafetySecurityFeatures] =
    useState(false);
  const [allParkingFeatures, setAllParkingFeatures] = useState(false);
  const [allutilitiesFeatures, setAllutilitiesFeatures] = useState(false);
  const [allCommunityFeatures, setAllCommunityFeatures] = useState(false);
  const [allPetFeatures, setAllPetFeatures] = useState(false);

  const [features, setFeatures] = useState({
    generalFeatures: [
      { name: "hasEnsuite", checked: false, label: "Ensuite" },
      { name: "hasPoPCeiling", checked: false, label: "POP Ceiling" },
      { name: "hasWalkInClosets", checked: false, label: "Walk-In-Closets" },
      { name: "hasAirConditioning", checked: false, label: "Air Conditioning" },
      {
        name: "hasSpeedInternet",
        checked: false,
        label: "High Speed Internet",
      },
      { name: "hasWineCeller", checked: false, label: "Wine Celler" },
      { name: "hasFurnished", checked: false, label: "Furnished" },
      { name: "hasWifi", checked: false, label: "Wi-Fi" },
      { name: "hasFibreOptics", checked: false, label: "Fibre Optics" },
      {
        name: "hasSatelliteTV",
        checked: false,
        label: "Satellite TV/DSTV Wiring",
      },
      { name: "hasElevator", checked: false, label: "Elevator" },
      { name: "hasBoysQuarters", checked: false, label: "Boys Quarters" },
      {
        name: "hasSmartHomeTechnology",
        checked: false,
        label: "Smart Home Technology",
      },
    ],
    kitchenFeatures: [
      {
        name: "hasFullyEquippedKitcken",
        checked: false,
        label: "Fully Equipped Kitchen",
      },
      {
        name: "hasModernAppliances",
        checked: false,
        label: "Modern Appliances (Refrigerators etc)",
      },
      {
        name: "hasGraniteCountertops",
        checked: false,
        label: "Granite Countertops",
      },
      { name: "hasBreakfastBar", checked: false, label: "Breakfast Bar" },
      { name: "hasStorageRoom", checked: false, label: "Storage Room" },
      {
        name: "hasUpgradedBathroomFeatures",
        checked: false,
        label: "Upgraded Bathroom Features",
      },
      {
        name: "hasSpaLikeFeatures",
        checked: false,
        label: "Spa-like Features",
      },
      {
        name: "hasTileOrMarbleFeatures",
        checked: false,
        label: "Tile or Marble Features",
      },
    ],
    livingRoomFeatures: [
      { name: "hasOpenFloorPlan", checked: false, label: "Open Floor Plan" },
      {
        name: "hasLargeWindwos",
        checked: false,
        label: "Large Windows for Natural Light",
      },
      {
        name: "hasBuiltInHouseTheater",
        checked: false,
        label: "Built-in House Theater",
      },
    ],
    outdoorFeatures: [
      { name: "hasPrivateBackyard", checked: false, label: "Private Backyard" },
      {
        name: "hasPatioOrDarkSpace",
        checked: false,
        label: "Patio or Deck Space",
      },
      {
        name: "hasLandscapedGarden",
        checked: false,
        label: "Landscaped Garden",
      },
      { name: "hasSwimmingPool", checked: false, label: "Swimming Pool" },
    ],
    homeOfficeFeatures: [
      {
        name: "hasHomeOfficeSpace",
        checked: false,
        label: "Home Office Space",
      },
      {
        name: "hasBuiltInShelfOrBookSpace",
        checked: false,
        label: "Built-in Shelf and Book Space",
      },
      {
        name: "hasAmpleNaturalLight",
        checked: false,
        label: "Ample Natural Light",
      },
    ],
    safetySecurityFeatures: [
      { name: "hasSecuritySystem", checked: false, label: "Security System" },
      {
        name: "hasBulletProofDoors",
        checked: false,
        label: "Bullet Proof Doors",
      },
      { name: "hasGatedCompound", checked: false, label: "Gated Compound" },
      {
        name: "hasReinforcedDoorsAndWindows",
        checked: false,
        label: "Reinforced Doors & Windows",
      },
      {
        name: "hasGaurdedCommunity",
        checked: false,
        label: "Gaurded Community",
      },
      {
        name: "hasUniformedSecurity",
        checked: false,
        label: "Uniformed Security",
      },
    ],
    parkingFeatures: [
      { name: "hasParkingGarage", checked: false, label: "Parking Garage" },
      { name: "hasDriveWaySpace", checked: false, label: "Driveway Space" },
      {
        name: "hasStreetParkingAvaliability",
        checked: false,
        label: "Street Parking Availability",
      },
      {
        name: "hasPrivateParkingSpace",
        checked: false,
        label: "Private Parking Space",
      },
    ],
    utilitiesFeatures: [
      { name: "hasElectricity", checked: false, label: "Electricity" },
      { name: "hasBackupGenerator", checked: false, label: "Backup Generator" },
      { name: "hasInverter", checked: false, label: "Inverter" },
      { name: "hasBorehole", checked: false, label: "Borehole" },
      { name: "hasWaterBoard", checked: false, label: "Water Board" },
    ],
    communityFeatures: [
      {
        name: "hasProximityToSchools",
        checked: false,
        label: "Proximity to Schools",
      },
      {
        name: "hasProximityToShoppingMalls",
        checked: false,
        label: "Proximity to Shopping Malls",
      },
      {
        name: "hasProximityToSupermarkets",
        checked: false,
        label: "Proximity to Supper Markets",
      },
      {
        name: "hasNearByPublicTransportation",
        checked: false,
        label: "Near by Public Transportation",
      },
      {
        name: "hasAccessiblityViaBoltOrUber",
        checked: false,
        label: "Accessible via Bolt/Uber",
      },
    ],
    petFeatures: [
      { name: "hasFencedBackyard", checked: false, label: "Fenced Backyard" },
      {
        name: "hasPetFriendlyNeighbourhood",
        checked: false,
        label: "Pet Friendly Neighbourhood",
      },
      {
        name: "hasNearbyWalkingTrailsAndSidewalks",
        checked: false,
        label: "Nearby Walking Trails & Sidewalks",
      },
    ],
  });

  const handleIndividualFeatureToggle = (group, featureName) => {
    const updatedFeatures = features[group].map((feature) =>
      feature.name === featureName
        ? { ...feature, checked: !feature.checked }
        : feature
    );

    setFeatures({
      ...features,
      [group]: updatedFeatures,
    });
  };

  const handleToggleSelectAllGroupedFeatures = (category, value) => {
    const newCheckedStatus = !value;

    const updatedFeatures = {
      ...features,
      [category]: features[category].map((feature) => ({
        ...feature,
        checked: newCheckedStatus,
      })),
    };

    setFeatures(updatedFeatures);
  };

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading..."} />{" "}
      </div>
    );
  return (
    <div className="ps-3">
      <form
        action=""
        className="form-group g-3 has-validation needs-validation w-full"
        onSubmit={handleSubmit}
      >
        <div className="">
          {/* Main Images Display Section */}
          <div className="row">
            {/* Placeholder for File Upload */}
            <div className="col">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: "248px",
                  borderRadius: "8px",
                  border: "1px solid #335f32",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <span className="plus-icon d-flex justify-content-center align-items-center">
                  <Icons iconName={"plus"} />
                </span>
                <p className="fw-bold text-success uppercase mt-3">
                  ADD PROPERTY IMAGES
                </p>
              </div>
            </div>

            {/* Display First Three Images */}
            {imagesToDisplay?.slice(0, 3).map((image) => (
              <div
                className="col house_image_wrapper position-relative"
                key={image.name}
                style={{
                  backgroundImage: `url(${image.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="img_overlay position-absolute"></div>
                <div onClick={() => handleImageClick(image)}>
                  <p className="img_open">
                    <span className="img_open-icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <div className="img_footer position-absolute">
                  <div className="mt-4">
                    <div>
                      <p className="img_name m-0">{image.name}</p>
                    </div>
                    <p className="img_type mt-2">
                      {convertToTitleCase(image.type)}
                    </p>
                  </div>
                  <div className="img_delete_icon">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <input
            type="file"
            className="input-image"
            ref={estate_image_ref}
            alt=""
            accept="image/*"
            onChange={handleChange}
            multiple
          />
        </div>

        {/* Thumbnails Section */}
        <div className="col h-25 thumbs-wrapper mt-3 mb-4">
          {imagesToDisplay.length > 3 &&
            imagesToDisplay.slice(3).map((image, index) => (
              <div className="thumb position-relative d-flex flex-column me-2">
                <div
                  className="position-relative w-full h-full thumb_img"
                  style={{
                    backgroundImage: `url(${image.image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="thumb_img_overlay position-absolute"></div>
                  <p
                    className="thumb_open"
                    onClick={() => handleImageClick(image)}
                  >
                    <span className="thumb_open_icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <p className="delete_icon_thumbs">
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    iconWidth={"15px"}
                    width={"25px"}
                    height={"29px"}
                    type={"button"}
                    onBtnClick={() => openDeleteModal(image)}
                  />
                </p>
                <p
                  className="my-0 text-success"
                  title={image.name}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                >
                  {image.name}
                </p>
              </div>
            ))}
        </div>

        {/* File Input */}
        <input
          type="file"
          className="d-none"
          ref={hiddenFileInputRef}
          accept="image/*"
          onChange={handleChange}
          multiple
        />

        <div className="row">
          <div className="col-md-4 mb-2 position-relative">
            <FormInput
              inputDescription
              inputDescriptionText={
                "If your organization doesn’t name properties, you can name the property with the house type, the number of beds, and the number of baths. E.g 2bed 2baths Semi Detached. If you have a property name, for example “Prime Court”, you can name it with the property name."
              }
              required
              labelName={"Name"}
              placeholder={"Name"}
              value={formData.name}
              onChange={(name, value) =>
                setFormData({ ...formData, name: value })
              }
            />
          </div>
          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Type"}
              placeholder={"Select"}
              required={true}
              options={houseTypes}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
            />
          </div>
          {estateDetails ? (
            <>
              <div className="col-md-4 mb-2">
                <FormInput
                  labelName={"State"}
                  type={"text"}
                  value={convertToTitleCase(estateDetails?.city?.stateId)}
                  required
                  placeholder={"Select"}
                  readOnly
                />
              </div>
              <div className="col-md-4 mb-2">
                <FormInput
                  placeholder={"Select"}
                  labelName={"City"}
                  type={"text"}
                  value={estateDetails?.city?.name}
                  required
                  readOnly
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4 mb-2">
                <Dropdown
                  labelName={"State"}
                  required
                  placeholder={"Select"}
                  options={states}
                  onChange={(e) => {
                    setFormData({ ...formData, cityId: e.target.value });
                    setStateId(e.target.value);
                    refetch();
                  }}
                />
              </div>
              <div className="col-md-4 mb-2">
                <Dropdown
                  labelName={"City"}
                  required
                  placeholder={"Select"}
                  options={city}
                  onChange={(e) => {
                    setFormData({ ...formData, cityId: e.target.value });
                    setCityId(e.target.value);
                    setCityError("");
                  }}
                  isLoading={isCityLoading}
                  disabled={city?.length < 0}
                  error={cityError}
                />
              </div>
            </>
          )}
          <div
            className="col-md-4 mb-2"
            onBlur={(e) =>
              setFormData({
                ...formData,
                price: !onlyNumbers(e.target.value)
                  ? formattedPrice(formData.price)
                  : formattedPrice(e.target.value),
              })
            }
          >
            <FormInput
              labelName={"Price"}
              name={"price"}
              type={"text"}
              value={formattedPrice(formData.price)}
              onChange={(name, value) => {
                if (onlyNumbers(value)) {
                  setFormData({
                    ...formData,
                    price: value,
                  });
                } else {
                  setFormData({
                    ...formData,
                    price: "",
                  });
                }
              }}
              required
              placeholder={"How much is this house selling for?"}
            />
          </div>
          <div
            className="col-md-4 mb-2"
            onBlur={(e) => {
              const youtubeLink = formData["videoUrl"]?.trim(); // Trim whitespace
              if (!youtubeLink) return; // If the field is empty, do nothing

              const pattern = /watch\?v=([a-zA-Z0-9_-]+)/;
              const match = youtubeLink.match(pattern);
              const embedPattern =
                /https:\/\/www.youtube.com\/embed\/([a-zA-Z0-9_-]+)/;
              const embedMatch = youtubeLink.match(embedPattern);

              if (embedMatch) {
                // Link is already in embed format, no need for further processing
                return;
              }

              if (match) {
                setFormData({
                  ...formData,
                  videoUrl: `https://www.youtube.com/embed/${match[1]}`,
                });
              } else {
                toast.warning(
                  "Invalid YouTube link. Please provide a valid link."
                );
                setFormData({
                  ...formData,
                  videoUrl: "",
                });
              }
            }}
          >
            <FormInput
              labelName={"Video URL"}
              name={"videoUrl"}
              type={"text"}
              placeholder={"Add a YouTube video of the property"}
              value={formData.videoUrl}
              onChange={(name, value) =>
                setFormData({ ...formData, videoUrl: value })
              }
            />
          </div>
          {estateDetails ? (
            <>
              <div className="col-md-4 mb-2">
                <FormInput
                  labelName={"Address"}
                  name={"address"}
                  type={"text"}
                  required={true}
                  placeholder={"No 123 Joseph Howe Drive"}
                  value={estateDetails?.address}
                  readOnly
                />
              </div>
              <div className="col-md-4 mb-2">
                <FormInput
                  labelName={"Popular Landmark"}
                  name={"landmark"}
                  type={"text"}
                  value={estateDetails?.landmark}
                  placeholder={"NNPC fueling station"}
                  readOnly
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-4 mb-2">
                <FormInput
                  labelName={"Address"}
                  name={"address"}
                  type={"text"}
                  required={true}
                  placeholder={"No 123 Joseph Howe Drive"}
                  value={formData.address}
                  onChange={(name, value) =>
                    setFormData({ ...formData, address: value })
                  }
                />
              </div>
              <div className="col-md-4 mb-2">
                <FormInput
                  labelName={"Popular Landmark"}
                  name={"popular-landmark"}
                  type={"text"}
                  placeholder={"NNPC fueling station"}
                  value={formData.landmark}
                  onChange={(name, value) =>
                    setFormData({ ...formData, landmark: value })
                  }
                />
              </div>
            </>
          )}
          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"Units Left"}
              name={"units"}
              type={"text"}
              required={true}
              placeholder={"Select unit"}
              value={formData.units}
              onChange={(name, value) => {
                const cleanedValue = value
                  .replace(/[^\d,]/g, "")
                  .replace(/^,/, "");

                // Check if the value matches the pattern for comma after every three digits
                const regex = /^(\d{1,3},?)*$/;
                if (regex.test(cleanedValue) || cleanedValue === "") {
                  setFormData({
                    ...formData,
                    units: cleanedValue,
                  });
                }
              }}
            />
          </div>
          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"3D Tour Link"}
              placeholder={"Add a 3D tour link"}
              value={formData.threeDTourLink}
              onChange={(name, value) =>
                setFormData({ ...formData, threeDTourLink: value })
              }
            />
          </div>
          <div className="col-md-4 mb-2">
            <FormInput
              labelName={"Virtual Tour Link"}
              placeholder={"Add a virtual tour link"}
              value={formData.virtualTourLink}
              onChange={(name, value) =>
                setFormData({ ...formData, virtualTourLink: value })
              }
            />
          </div>
          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Segment"}
              placeholder={"Select"}
              required={true}
              options={segmentLists}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  segment: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Category"}
              placeholder={"Select"}
              required={true}
              options={categoryLists}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  category: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Completion Status"}
              placeholder={"Select"}
              required={true}
              options={houseStatusLists}
              onChange={(e) => {
                setCompletionStatus(e.target.value);
                setFormData({
                  ...formData,
                  completionStatus: e.target.value,
                });
              }}
            />
          </div>
          {completionStatus === "UNDER_CONSTRUCTION" && (
            <div className="col-md-4 mb-2">
              <FormInput
                type={"date"}
                required
                labelName={"Completion Date"}
                placeholder={"When will it be completed?"}
                onChange={(name, value) =>
                  setFormData({ ...formData, completionDate: value })
                }
              />
            </div>
          )}

          {/* {completionStatus === status[2]?.id && (
            <div className="col-md-4 mb-2">
              <Dropdown
                labelName={"Off Plan Sales Percentage"}
                placeholder={"Select sales percentage"}
                required={true}
                options={salesPercentage}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    completionPercentage: e.target.value,
                  });
                }}
              />
            </div>
          )} */}

          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Land Title Document"}
              placeholder={"Select"}
              required={true}
              options={landTitleDocs}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  landTitleDocument: e.target.value,
                });
              }}
            />
          </div>

          <div className="col-md-4 mb-2">
            <Dropdown
              labelName={"Ownership Document"}
              placeholder={"Select"}
              required={true}
              options={ownershipDoc}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  ownershipDocument: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <MultitextInput
              required
              label={"Description"}
              value={formData.description}
              placeholder={"Enter a detailed description of this house..."}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
          </div>
          <div className="col-md-4">
            <MultitextInput
              label={"Unique Selling Proposition"}
              value={formData.uniqueSellingProposition}
              placeholder={
                "What makes this house special and different from others..."
              }
              onChange={(e) =>
                setFormData({
                  ...formData,
                  uniqueSellingProposition: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-4">
            <MultitextInput
              label={"Value and Investment"}
              value={formData.valueAndInvestment}
              placeholder={
                "Why is this house a good investment and how would you advise customers to use it to deliver outstanding returns..."
              }
              onChange={(e) =>
                setFormData({ ...formData, valueAndInvestment: e.target.value })
              }
            />
          </div>
        </div>

        <div className="row pt-3">
          <h2 className="mt-4">Specifications and Amenities</h2>
          <div className="row m-0 mt-2 p-0">
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                labelName={"Bedrooms"}
                min={"0"}
                width={"100%"}
                type={"number"}
                required={true}
                placeholder={"Number of bedrooms"}
                value={formData.bedrooms}
                onChange={(name, value) =>
                  setFormData({ ...formData, bedrooms: value })
                }
              />
            </div>
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                labelName={"Guest Toilets"}
                width={"100%"}
                type={"number"}
                placeholder={"Number of guest Toilets"}
                value={formData.guestToilets}
                onChange={(name, value) =>
                  setFormData({ ...formData, guestToilets: value })
                }
              />
            </div>
            <div className="col-sm-6 col-md-4 col-xxl-3">
              <FormInput
                labelName={"Baths"}
                width={"100%"}
                min={"0"}
                type={"number"}
                required={true}
                placeholder={"Number of bathrooms"}
                value={formData.bathrooms}
                onChange={(name, value) =>
                  setFormData({ ...formData, bathrooms: value })
                }
              />
            </div>
            <div
              className="col-sm-6 col-md-4 col-xxl-3"
              onBlur={(e) =>
                setFormData({
                  ...formData,
                  squareMeters: !onlyNumbers(e.target.value)
                    ? formattedPrice(formData.squareMeters)
                    : formattedPrice(e.target.value),
                })
              }
            >
              <FormInput
                labelName={"Square Meters"}
                width={"100%"}
                placeholder={"Size of this home (in Sqm)"}
                value={formData.squareMeters}
                onChange={(name, value) =>
                  setFormData({ ...formData, squareMeters: value })
                }
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"generalFeat"} />{" "}
              <span className="me-4 ps-2">General Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allGeneralFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allGeneralFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllGeneralFeatures(!allGeneralFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "generalFeatures",
                    allGeneralFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.generalFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllGeneralFeatures(false);
                  handleIndividualFeatureToggle("generalFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"kitchenFeat"} />{" "}
              <span className="me-4 ps-2">Kitchen Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allKitchenFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allKitchenFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllKitchenFeatures(!allKitchenFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "kitchenFeatures",
                    allKitchenFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.kitchenFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllKitchenFeatures(false);
                  handleIndividualFeatureToggle("kitchenFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"livingRoomFeat"} />{" "}
              <span className="me-4 ps-2">Living Room Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allLivingRoomFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allLivingRoomFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllLivingRoomFeatures(!allLivingRoomFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "livingRoomFeatures",
                    allLivingRoomFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.livingRoomFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllLivingRoomFeatures(false);
                  handleIndividualFeatureToggle(
                    "livingRoomFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"OutdoorFeat"} />{" "}
              <span className="me-4 ps-2">Outdoor Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allOutdoorFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allOutdoorFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllOutdoorFeatures(!allOutdoorFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "outdoorFeatures",
                    allOutdoorFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.outdoorFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllOutdoorFeatures(false);
                  handleIndividualFeatureToggle("outdoorFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"homeOfficeFeat"} />{" "}
              <span className="me-4 ps-2">Home Office Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allHomeOfficeFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allHomeOfficeFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllHomeOfficeFeatures(!allHomeOfficeFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "homeOfficeFeatures",
                    allHomeOfficeFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.homeOfficeFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllHomeOfficeFeatures(false);
                  handleIndividualFeatureToggle(
                    "homeOfficeFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"securityFeat"} />{" "}
              <span className="me-4 ps-2">Safety & Security Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allSafetySecurityFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allSafetySecurityFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllSafetySecurityFeatures(!allSafetySecurityFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "safetySecurityFeatures",
                    allSafetySecurityFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.safetySecurityFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllSafetySecurityFeatures(false);
                  handleIndividualFeatureToggle(
                    "safetySecurityFeatures",
                    spec.name
                  );
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"parkingFeat"} />{" "}
              <span className="me-4 ps-2">Parking Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allParkingFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allParkingFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllParkingFeatures(!allParkingFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "parkingFeatures",
                    allParkingFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.parkingFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllParkingFeatures(false);
                  handleIndividualFeatureToggle("parkingFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"utilsFeat"} />{" "}
              <span className="me-4 ps-2">Utilities</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allutilitiesFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allutilitiesFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllutilitiesFeatures(!allutilitiesFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "utilitiesFeatures",
                    allutilitiesFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.utilitiesFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllutilitiesFeatures(false);
                  handleIndividualFeatureToggle("utilitiesFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"communityFeat"} />{" "}
              <span className="me-4 ps-2">Community Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allCommunityFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allCommunityFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllCommunityFeatures(!allCommunityFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "communityFeatures",
                    allCommunityFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.communityFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllCommunityFeatures(false);
                  handleIndividualFeatureToggle("communityFeatures", spec.name);
                }}
              />
            </div>
          ))}

          <div
            className="d-flex justify-content-between align-items-center py-2 px-3 my-2"
            style={{ backgroundColor: colors.grey }}
          >
            <h5 className="m-0 p-0">
              <Icons iconName={"petFeat"} />{" "}
              <span className="me-4 ps-2">Pet Features</span>
            </h5>
            <div className="d-flex gap-2 align-items-center fw-semibold">
              <p className="m-0 pt-1">
                {allPetFeatures ? "Unselect All" : "Select All"}
              </p>
              <CheckInput
                name="toggleAll"
                checked={allPetFeatures}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllPetFeatures(!allPetFeatures);
                  handleToggleSelectAllGroupedFeatures(
                    "petFeatures",
                    allPetFeatures
                  );
                }}
              />
            </div>
          </div>

          {features?.petFeatures?.map((spec) => (
            <div
              key={spec.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">{spec.label}</p>
              <CheckInput
                name={spec.name}
                checked={spec.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  setAllPetFeatures(false);
                  handleIndividualFeatureToggle("petFeatures", spec.name);
                }}
              />
            </div>
          ))}
        </div>

        <FooterButtons
          largeLoader={createHouse.isLoading}
          loading={createHouse.isLoading}
          disabled={createHouse.isLoading}
          cancelBtn={() => navigate("/properties")}
          height={"100px"}
        />
      </form>

      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Image"}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        confirmModal={() => handleDelete()}
        cancelModal={() => setShowDeleteModal(false)}
      >
        <p className="text-center">
          Are you sure you want to delete this image?
        </p>
      </Modal>

      <Modal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={"Review house image name?"}
        confirmModal={() => {
          let index = replaceImage
            ? imageToReplaceOrRemoveIndex
            : imagesToDisplay?.length - 1;
          handleChangeImageName(index);
        }}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-4">
              <h6 className="d-flex justify-content-center text-center">
                What part of the house is this? Name the part of the house for
                this image. Customers will be able to see what part of the house
                this is and easily navigate through your property.{" "}
              </h6>
              <h6 className="d-flex justify-content-center">
                <strong className="text-danger text-center">
                  Are you okay with the name below?
                </strong>
              </h6>
              <div className="mt-4">
                <FormInput
                  value={newImageName}
                  onChange={(name, value) => {
                    setNewImageName(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showReplaceOrRemoveImageModal}
        closeModal={() => setShowReplaceOrRemoveImageModal(false)}
        title={"Replace Or Remove Selected Image"}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-4">
              <h6 className="d-flex justify-content-center">
                What action would you like to perform on this image?
              </h6>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-5 mt-4">
            <div>
              <Button
                type={"button"}
                btnText={"Remove"}
                onBtnClick={() =>
                  handleRemoveImage(
                    imageToReplaceOrRemove,
                    imageToReplaceOrRemoveIndex
                  )
                }
                btntextcolor={colors.primary}
                backgroundcolor={colors.white}
                colorHover={colors.hover}
                borderHover={colors.hover}
                backgroundHover={colors.white}
              />
            </div>
            <div>
              <Button
                type={"button"}
                btnText={"Replace"}
                onBtnClick={() =>
                  handleReplaceImage(
                    imageToReplaceOrRemove,
                    imageToReplaceOrRemoveIndex
                  )
                }
                // disabled={disabled}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showImageModal}
        closeModal={() => setShowImageModal(false)}
        title="Name Uploaded Image"
        confirmButtonLabel="Save"
        crossLine={false}
        confirmModal={saveImageName}
        cancelModal={() => setShowImageModal(false)}
      >
        <div className="text-center mb-3">
          <p>
            This is how <strong>{editableImageName}</strong> will look to
            customers
          </p>
        </div>
        <div className="d-flex justify-content-center mb-3">
          {selectedImage && (
            <div className="position-relative">
              <div
                style={{
                  maxHeight: "20rem",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                <img
                  src={selectedImage.image}
                  alt={selectedImage.name}
                  className="img-fluid w-100 h-100"
                />
              </div>

              <div
                className="position-absolute d-flex justify-content-between align-items-center p-1"
                style={{ width: "100%", bottom: "1rem", left: "0.5rem" }}
              >
                <span
                  className="bg-white text-success px-2 py-1 fw-bold"
                  style={{ marginLeft: "10px", borderRadius: "15px" }}
                >
                  {editableImageName}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="mx-3 mt-4 mb-0">
          <Dropdown
            labelName={"Image Type"}
            width={"100%"}
            required
            placeholder={"Select"}
            options={imageTypes}
            value={convertToTitleCase(editableImageType)}
            onChange={(e) => setEditableImageType(e.target.value)}
          />
        </div>
        <div className="mx-3 mt-2 mb-0">
          <FormInput
            required
            labelName={"Image Name (name the part of this house)"}
            value={editableImageName}
            onChange={(name, value) => setEditableImageName(value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateHouse;
