import "../Dashboard/dashboard.css";
import { useNav } from "../../hooks/useNav";
import useAuth from "../../hooks/useAuth";
import useGetDashboardSummary from "./hooks/useGetDashboardSummary";
import InfoCard from "../../Components/Common/InfoCard";
import { useEffect } from "react";
import SkeletonLoader from "../../Components/SkeletonLoader";

const Dashboard = () => {
  const { authUser } = useAuth();
  const username = authUser?.user?.name;
  const organizationName = authUser?.user?.organization?.name;

  useNav({ mainTitle: `Hello, ${username}`, isHome: true });

  const {
    data: dashboardSummary,
    isLoading,
    refetch,
    isRefetching,
  } = useGetDashboardSummary();

  useEffect(() => {
    if (!dashboardSummary) {
      refetch();
    }
  }, [dashboardSummary]);

  useEffect(() => {
    document.title = `Giddaa - ${organizationName} - Dashboard`;
  }, []);

  return (
    <div className="dashboard my-3">
      {/* Second Section */}
      <div className="row ps-2">
        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalHouses ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Unique Houses"
              )
            }
            width={"100%"}
          />
        </div>
        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalHousingUnits ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Housing Units"
              )
            }
            width={"100%"}
          />
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalEstates ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Estates"
              )
            }
            width={"100%"}
          />
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalPlans ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? <SkeletonLoader /> : "Number of Plans"
            }
            width={"100%"}
          />
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalHomeViewings ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Active House Viewings"
              )
            }
            width={"100%"}
          />
        </div>

        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalProspects ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Prospects"
              )
            }
            width={"100%"}
          />
        </div>
        
        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalUnderReviewApplications ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Unhandled Applications"
              )
            }
            width={"100%"}
          />
        </div>
        
        <div className="col-md-3 col-sm-6 mb-4">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                dashboardSummary?.totalRejectedApplications ?? "0"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Number of Rejected Applications"
              )
            }
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
